import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../layouts/Layout"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

const AboutPage = ({ data }) => (
  <Layout>
    <SiteMetadata title="אודות" description="Sample description" />

    <div className="bg-gray-100 p-5">
      <div className="container py-12 lg:pb-16 bg-white rounded-md">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0 text-right">
            <h1 className="text-3xl leading-tight font-semibold tracking-tight text-gray-900 sm:text-4xl ">
            אודות
            </h1>
            <h2 className="text-xl mt-3 leading-tight font-semibold tracking-tight text-blue-500 sm:text-2xl">
            ירון ברוך מייסד ומנהל Yaron Baruh אדריכלות ועיצוב פנים.
            </h2>
            <div className="text-lg mt-4 leading-loose">
            <p>
בעל <b>נסיון עשיר של מעל 12 שנים </b>בתכנון אדריכלי ועיצוב פנים ייחודי של פרויקטים בתחום הבנייה הפרטית,
<br/>
תכנון ועיצוב בתים פרטיים, דירות יוקרה, וילות נופש.
<br/>
השילוב בין דרישות הלקוח למגבלות המציאות יוצר אתגר תכנוני - זהו בדיוק המקום המתאים  להשראה ול<b>יצירתיות</b>.
<br/>
סוד ההצלחה בתכנון פרויקט הוא בהקשבה מלאה לכל צורכי הלקוח, לרצונותיו ושאיפותיו וביחד עם יעוץ , <b>הכוונה אישית נכונה, זמינות ושרות מעולה</b>.
<br/>
<br/>
<b>אין פרויקט העומד בפני התכנון</b>
<br/>
ממש כמו לחבר חלקים של פאזל לתמונה מושלמת, ראיית התכנון האדריכלי כמקשה אחת הכוללת את תכנון הפרויקט, עיצוב הפנים וייעודו, היא המתכון המנצח לתכנון ועיצוב מושלם. כמו כן מיזוג בין שאיפות הלקוח התקציב ואתגרי התכנון
<br/>
התכנון במשרדי מתאפיין במתן פתרונות יצירתיים, פרקטיים וחדשנים בעלי אופי ייחודי על בסיס התאמה מלאה לצורכי הלקוח, לחומרים ולמסגרת התקציב.
<br/>
תוצר התכנון הסופי הוא פרי השאיפות שלכם, בתכנון ונגיעות עיצוביות שלי, בליווי מקצועי משלב התכנון הראשוני וכלה בסיום הבניה, בשיתוף מלא עם הלקוח.
<br/>
<b>שירותי הדמיה ממוחשבת</b> הם כחלק בלתי נפרד מהתכנון, מלווים כל פרויקט בדייקנות, מאפשרים לכל לקוח לראות ולהבין את הפרויקט שאליו שואפים עוד בשלבי התכנון המתקדמים ובמהלכם.
<br/>
<br/>
כל פרויקט חדש מתחיל ב<b>פגישת הכירות על קפה</b> (או משקה קל אחר).
<br/>
תמיד ישנה האפשרות לתיאום פגישות העבודה בבית הלקוח, בזמן שהכי נח ומתאים, יתרון שהופך את הליך התכנון לנוח, נעים ויעיל, וכמובן את התוצר הסופי למושלם. 
<br/>
אחרי ששוברים את הקרח אפשר להתחיל בהיכרות מעמיקה עם חלומכם. לאחר <b>הקשבה מלאה</b>, קבלה ורישום רצונות ושאיפות, מתחילה מלאכת התכנון והעיצוב. 
<br/>
<br/>
בדרך <b>להגשמת חלומכם</b> ובהתאם לרצונותיכם, יוכנו עבורכם סקיצות תכנון שונות, בהתאם לקונספט המבוקש, ולמסגרת התקציב, מלוות בהדמיה ממוחשבת עד לשביעות רצונכם המלאה ואישורכם. ההזדמנות לתכנן ולעצב את חלומכם ,מתחילה מלצייר ולשרטט משהו בסקיצה ידנית או במחשב ולראות אותו הופך למציאות, מדהימה אותי כל פעם מחדש, והסיפוק של לקוחות מרוצים הופך את החוויה ל<b>תענוג גדול</b>.  
          </p>
          </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-2/5 md:pl-12">
            <Img
              fluid={data.author.childImageSharp.fluid}
              alt="John Doe"
              className="rounded-md shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
    <Newsletter />
  </Layout>
)

export default AboutPage

export const query = graphql`
  query {
    author: file(relativePath: { eq: "author.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 480, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
